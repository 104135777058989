@font-face {
  font-family: "Park-overseas";
  src: url("./Park-overseas.eot?8llh89");
  src: url("./Park-overseas.eot?8llh89#iefix") format("embedded-opentype"),
    url("./Park-overseas.ttf?8llh89") format("truetype"),
    url("./Park-overseas.woff?8llh89") format("woff"),
    url("./Park-overseas.svg?8llh89#Park-overseas") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Park-overseas" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
.icon-copyright:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-forword:before {
  content: "\e904";
}
.icon-forword-bold:before {
  content: "\e905";
}
.icon-hemburger:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-light:before {
  content: "\e908";
}
.icon-linckedin:before {
  content: "\e909";
}
.icon-madel:before {
  content: "\e90a";
}
.icon-product:before {
  content: "\e90b";
}
.icon-whatsup:before {
  content: "\e90c";
}
.icon-whatsup-bold:before {
  content: "\e90d";
}
