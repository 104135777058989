@font-face {
  font-family: "DMSans";
  src: local("DMSans"), url(./fonts/DMSans-Regular.ttf) format("ttf");
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

:root {
  --color-primary: #c69c6d;
  --color-black: #020113;
  --color-light-grey: #6e6e6e;
}

* {
  margin: 0px;
  padding: 0px;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: "DM Sans", sans-serif;
  overflow: hidden;
  position: relative;
}

section {
  padding: 7em 0;
  position: relative;
  overflow: hidden;
}

section.highlights {
  padding: 3em 0;
}

p,
li {
  white-space: pre-wrap !important;
}

.btn-primary {
  border: none;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-btn {
  height: 50px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  border: none;
  background-color: var(--color-primary);
}

.btn-primary.my-btn .loading {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 5em;
  border: 3px solid white;
  border-bottom: 5px solid transparent;
  animation: load 700ms ease-in forwards infinite;
}

[type="button"]:disabled,
[type="reset"]:disabled,
[type="submit"]:disabled,
button:disabled {
  cursor: not-allowed;
}

.btn-primary:not(:disabled):not(:disabled):active {
  background-color: var(--color-primary);
}

.btn-primary:not(:active):not(:active):disabled {
  background-color: var(--color-primary);
}

@keyframes load {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

.section-heading-top {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-primary);
  text-align: center;
  margin: 0px;
}

.section-heading {
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  color: var(--color-black);
  text-align: center;
}

.section-description {
  text-align: center;
  color: var(--color-light-grey);
  /* padding: 0 8em; */
  box-sizing: border-box;
}

.color-grey {
  color: var(--color-light-grey);
}

.error {
  color: red;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link {
  color: #000000 !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    position: relative;
  }

  .navbar-expand-lg .navbar-nav .nav-link.selected::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 50%;
    background-color: #000000;
    bottom: 0px;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, 0%);
  }
}

.login-content {
  width: 50%;
}

@media only screen and (max-width: 990px) {
  section {
    padding: 6em 0;
  }

  section.highlights {
    padding: 3em 0;
  }

  .navbar-nav .nav-link.selected,
  .navbar-nav .show > .nav-link {
    color: var(--color-primary) !important;
  }

  .login-content {
    width: 100%;
  }

  .login-container .left {
    padding: 1em !important;
  }
}
